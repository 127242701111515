import { TStoryblock } from '@hubcms/domain-storyblock';

import { PagedArticlesData, createPagedArticlesData } from './paged-articles-data';

type StoryblockAds = {
  positions: number[];
  formats: string[];
};

export type SectionData<PaginationResponse = never> = {
  title: string;
  noContentMessage: string;
  storyblocks: TStoryblock[];
  storyblockAds: StoryblockAds;
  pagedArticlesData: PagedArticlesData<PaginationResponse>;
  theme: string;
  leadingAdFormat: string;
  templateDividerAdFormat: string;
  leftPipeAdFormat: string;
  rightPipeAdFormat: string;
};

export function createSectionData<PaginationResponse = never>({
  title = '',
  noContentMessage = '',
  storyblocks = [],
  storyblockAds = {
    positions: [],
    formats: [],
  },
  pagedArticlesData = createPagedArticlesData<PaginationResponse>(),
  theme = 'none',
  leadingAdFormat = '',
  templateDividerAdFormat = '',
  leftPipeAdFormat = '',
  rightPipeAdFormat = '',
}: Partial<SectionData<PaginationResponse>> = {}): SectionData<PaginationResponse> {
  return {
    title,
    noContentMessage,
    storyblocks,
    storyblockAds,
    pagedArticlesData,
    theme,
    leadingAdFormat,
    templateDividerAdFormat,
    leftPipeAdFormat,
    rightPipeAdFormat,
  };
}
