export function getInitialSideContentRowSpan({
  hasPrependChildren,
  isLastArticleOfFirstPage,
  initialPageSize,
  pageSize,
}: {
  hasPrependChildren: boolean;
  isLastArticleOfFirstPage: boolean;
  initialPageSize: number;
  pageSize: number;
}) {
  if (isLastArticleOfFirstPage) {
    return initialPageSize + (hasPrependChildren ? 1 : 0);
  }
  return pageSize;
}
