import { createContext, useContext } from 'react';

import { TeaserGridExtensions } from '@hubcms/domain-teaser-grid';

export interface TeaserGridExtensionsContextValue {
  extensions: TeaserGridExtensions;
  addExtensions: (e: TeaserGridExtensions) => void;
}

const defaultContext: TeaserGridExtensionsContextValue = {
  extensions: {},
  addExtensions: () => null,
};

export const TeaserGridExtensionsContext = createContext<TeaserGridExtensionsContextValue>(defaultContext);

export function useTeaserGridExtensionsContext() {
  const teaserGridExtensionsContext = useContext(TeaserGridExtensionsContext);

  return teaserGridExtensionsContext;
}
