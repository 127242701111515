import { CookData, isMhluHomePageContext } from '@hubcms/domain-cook';
import { createSectionData } from '@hubcms/domain-section';
import { getStoryblocks } from '@hubcms/utils-storyblock';
import { TeaserPropsOptions } from '@hubcms/domain-teaser';

import { getHomePageAdParameters } from '../../utils/getHomePageAdParameters';
import { MapSectionPageReturnType } from '../../domain/map-section-page-return-type';

export function createMhluHomePage(cookData: CookData, teaserPropsOptions: TeaserPropsOptions): MapSectionPageReturnType {
  if (!isMhluHomePageContext(cookData.context)) {
    return null;
  }
  const { sectionParams } = cookData;

  const sectionData = createSectionData();

  const areaNames = cookData.context.rootGroup.areaNames?.filter(name => name !== 'nonContent') || [];
  sectionData.storyblocks = getStoryblocks(cookData.context.rootGroup as never, {
    sectionParams,
    teaserPropsOptions,
    areaNames,
  });

  sectionData.storyblockAds = getHomePageAdParameters(cookData.context.rootGroup.nonContent);

  if (sectionData.storyblocks.length === 0) {
    sectionData.noContentMessage = sectionParams['section-tag.noresults'];
  }

  sectionData.leadingAdFormat = 'leaderboard';
  sectionData.leftPipeAdFormat = sectionParams['b2b.leftpipe.adformat'];
  sectionData.rightPipeAdFormat = sectionParams['b2b.rightpipe.adformat'];

  return {
    sectionData,
  };
}
