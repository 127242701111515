import { CookData, IndexPageTagData, isB2bTagPage, isTagPage } from '@hubcms/domain-cook';
import { createSectionData, createTagPageTemplateData } from '@hubcms/domain-section';
import { getStoryElements } from '@hubcms/utils-story';
import { getPageDataFromExtendedDataSource } from '@hubcms/utils-pagination';
import { getIsTeaserDateShown } from '@hubcms/utils-teaser';
import { isFirstItemWithKey } from '@hubcms/utils-browser';
import { TeaserPropsOptions } from '@hubcms/domain-teaser';

import { buildPagination } from '../../utils/buildPagination';
import { getArticles } from '../../utils/getArticles';
import { MapSectionPageReturnType } from '../../domain/map-section-page-return-type';

export function createTagPage(cookData: CookData, teaserPropsOptions: TeaserPropsOptions): MapSectionPageReturnType {
  if (!isTagPage(cookData)) {
    return null;
  }
  const { sectionParams } = cookData;
  const intro = getIntro(cookData);

  const pagedArticlesData = buildTagPagedArticlesData(cookData, teaserPropsOptions);
  const sectionData = createSectionData({ pagedArticlesData });

  if (!intro) {
    sectionData.title = cookData.tagTitle;
  }

  if (pagedArticlesData.initialItems.length === 0 && !cookData.tagTitle) {
    sectionData.noContentMessage = sectionParams['section-tag.noresults'];
  }

  if (isB2bTagPage(cookData)) {
    sectionData.theme = 'sponsored-content';
  }

  sectionData.leadingAdFormat = 'leaderboard';

  const templateData = createTagPageTemplateData();
  if (intro) {
    const { bodyElements, storyHeaderData } = getStoryElements(intro, { hasNoMedia: true, sectionParams });
    templateData.bodyElements = bodyElements;
    templateData.storyHeaderData = storyHeaderData;
    sectionData.templateDividerAdFormat = sectionParams['b2b.tagintro.divider.adformat'];
  }

  return {
    sectionData,
    templateData,
  };
}

function getIntro(cookData: CookData<IndexPageTagData>) {
  return cookData.intro && cookData.intro.length > 0 ? cookData.intro[0] : null;
}

function buildTagPagedArticlesData(cookData: CookData<IndexPageTagData>, teaserPropsOptions: TeaserPropsOptions) {
  const { sectionParams } = cookData;
  const intro = getIntro(cookData);

  const uniqueArticles = [
    ...(intro?.related.map(({ content }) => content) || []),
    ...(intro?.relatedTagIntroductions?.map(({ content }) => content) || []),
    ...(cookData.articles?.items || []),
  ].filter(isFirstItemWithKey('id'));

  const pagedArticlesData = buildPagination<IndexPageTagData>(sectionParams, {
    initialItems: getArticles(uniqueArticles, teaserPropsOptions),
    initialPageSizeKey: 'tagpage.pagination.webInitialAmount',
    extraPageSizeKey: 'tagpage.pagination.webExtraAmount',
    total: cookData.articles?.total,
    adFormatsKey: 'b2b.tagpage.ads.adformat',
    adPageSkipSequenceKey: 'b2b.tagpage.ads.pageskip',
  });
  pagedArticlesData.teaserHasDateTime = getIsTeaserDateShown('tag');

  pagedArticlesData.getPageDataFromResponse = responseData => {
    const pageData = getPageDataFromExtendedDataSource(responseData.articles);
    return {
      pageableItems: getArticles(pageData.pageableItems, teaserPropsOptions),
      hasMore: pageData.hasMore,
    };
  };

  return pagedArticlesData;
}
