import { sectionPageAreaOnePosition, sectionPageAreaOneTeasertype, sectionPageAreaTwoLayoutMobileApp } from '@hubcms/brand';
import { useCook } from '@hubcms/data-access-cook';
import { CustomizableGroupOptions, StoryblockAreas, TStoryblock } from '@hubcms/domain-storyblock';
import { TeaserData } from '@hubcms/domain-teaser';
import { TTeaserGrid } from '@hubcms/domain-teaser-grid';
import { useSublinkOptions } from '@hubcms/feature-my-section';
import { mapToCustomizableStoryblockGroup } from '@hubcms/utils-storyblock';

export function useSectionPageGroup(
  title: string,
  asideRecirculationStoryblocks: TStoryblock[],
  teaserAreas: StoryblockAreas<TeaserData>,
): TTeaserGrid {
  const { sectionParams } = useCook();

  const subLinkOptions = useSublinkOptions(sectionParams, title);

  const customizableGroupOptions: CustomizableGroupOptions = {
    type: 'CustomizableGroup',
    isHiddenOnMobile: false,
    areaOnePosition: sectionPageAreaOnePosition,
    title,
    areaOneOptions: {
      hasIntroDesktop: false,
      hasIntroMobile: false,
      isDefaultTeaser: sectionPageAreaOneTeasertype === 'default',
      isHighlighted: false,
    },
    areaTwoOptions: {
      hasImages: true,
      isAreaHiddenMobile: false,
      isHighlighted: false,
      isStackedMobile: sectionPageAreaTwoLayoutMobileApp === 'stacked',
    },
    areaThreeOptions: {
      hasImages: false,
      isAreaHiddenMobile: false,
      isHighlighted: false,
      isList: true,
      isListWithMobileSlider: false,
      isStackedMobile: false,
      listLayout: 'none',
      // A non-empty string is needed to ensure the group title doesn't span all column, but leaves place for the aside recirculation
      title: 'dummy',
    },
  };

  const mappedSectionTeaserAreas = {
    areaOne: [teaserAreas.groupArea[0]],
    areaTwo: teaserAreas.groupArea.slice(1, 3),
    areaThree: [],
  };

  const sectionPageStoryblock = mapToCustomizableStoryblockGroup(customizableGroupOptions, mappedSectionTeaserAreas);
  const asideRecirculationArea = sectionPageStoryblock.items.length - 1;

  sectionPageStoryblock.items[asideRecirculationArea].type = 'sectionAsideRecirculation';
  sectionPageStoryblock.items[asideRecirculationArea].data = {
    asideRecirculationStoryblocks,
  };
  sectionPageStoryblock.items[asideRecirculationArea].gridProps = {
    ...sectionPageStoryblock.items[asideRecirculationArea].gridProps,
    hasInverseMarginInlineSm: true,
    hasInverseMarginInlineMd: true,
  };

  const sectionPageTitle = sectionPageStoryblock.title;

  if (sectionPageTitle) {
    sectionPageTitle.data.subLink = subLinkOptions;
    sectionPageTitle.data.headerLevel = 1;
  }

  return sectionPageStoryblock;
}
