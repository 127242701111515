import { TTeaserGridItem, TeaserGridExtensions } from '@hubcms/domain-teaser-grid';
import { TStoryblock } from '@hubcms/domain-storyblock';

import { AsideRecirculation } from '../../AsideRecirculation';

export type GSectionAsideRecirculation = TTeaserGridItem<
  'sectionAsideRecirculation',
  {
    asideRecirculationStoryblocks: TStoryblock[];
  }
>;

export const sectionGridExtensions: TeaserGridExtensions = {
  sectionAsideRecirculation: (item: GSectionAsideRecirculation) => ({
    Component: AsideRecirculation,
    key: 'section-aside-recirculation',
    componentProps: {
      storyblocks: item.data.asideRecirculationStoryblocks,
    },
  }),
};
