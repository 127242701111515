import { MhluSectionTemplateData } from '@hubcms/domain-section';
import { TeaserData } from '@hubcms/domain-teaser';
import { TeaserGrid, TeaserGridExtensionsProvider } from '@hubcms/ui-teaser-grid';

import { sectionGridExtensions } from './Section/Pages/sectionGridExtensions';
import { useSectionPageGroup } from './Section/Pages/useSectionPageGroup';

type MHLUSectionOpeningProps = {
  templateData: MhluSectionTemplateData;
  articles: TeaserData[];
};

function MHLUSectionOpening({ templateData, articles }: MHLUSectionOpeningProps) {
  const teaserGridProps = useSectionPageGroup(templateData.groupTitle, templateData.asideRecirculationStoryblocks, {
    groupArea: articles,
  });

  return (
    <TeaserGridExtensionsProvider initialExtensions={sectionGridExtensions}>
      <TeaserGrid {...teaserGridProps} />
    </TeaserGridExtensionsProvider>
  );
}

export default MHLUSectionOpening;
