import { TStoryblock } from '@hubcms/domain-storyblock';

import { getAsideRecirculationStoryblocksForPage } from './getAsideRecirculationStoryblocksForPage';

export function calculateExtendedSideContentRowSpan(
  pageOptions: {
    nextPageIndex: number;
    pageSize: number;
    maxPage: number;
  },
  getAdFormatForPage: (index: number) => string | null,
  asideRecirculationStoryblocks: TStoryblock[],
) {
  const nextPageHasAd = !!getAdFormatForPage(pageOptions.nextPageIndex);
  const nextPageHasRecirculation = getAsideRecirculationStoryblocksForPage(
    asideRecirculationStoryblocks,
    pageOptions.nextPageIndex,
  );
  if (nextPageHasAd || nextPageHasRecirculation) {
    return 0;
  }
  if (pageOptions.nextPageIndex + 1 <= pageOptions.maxPage) {
    return (
      pageOptions.pageSize +
      calculateExtendedSideContentRowSpan(
        {
          ...pageOptions,
          nextPageIndex: pageOptions.nextPageIndex + 1,
        },
        getAdFormatForPage,
        asideRecirculationStoryblocks,
      )
    );
  }
  return pageOptions.pageSize;
}
