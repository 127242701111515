import { useMemo } from 'react';
import cx from 'classnames';

import { Ad } from '@hubcms/feature-ads';
import { useAdPositions } from '@hubcms/utils-ads';
import { usePager } from '@hubcms/utils-pagination';
import { getThemeDataAttributes } from '@hubcms/utils-theme';
import { Container } from '@hubcms/ui-container';
import { MostRecentTemplateData, SectionData } from '@hubcms/domain-section';
import { deduplicateObjectsByKey } from '@hubcms/utils-browser';
import { isLeaderboardAdHiddenOnMobile } from '@hubcms/brand';

import { StoryHeader } from '@hubcms/ui-story';
import { TeaserPropsOptions } from '@hubcms/domain-teaser';

import { InfiniteScrollArticleList } from '../InfiniteScrollArticleList';
import styles from './mostrecent.module.scss';

type MostRecentProps = {
  sectionData: SectionData;
  templateData: MostRecentTemplateData;
  teaserPropsOptions: TeaserPropsOptions;
};

export default function MostRecentPage({
  sectionData: { pagedArticlesData, theme },
  templateData: { title },
  teaserPropsOptions,
}: MostRecentProps) {
  const {
    items: articles,
    isLoading,
    loadPage,
    hasMore,
  } = usePager({
    pageSize: pagedArticlesData.pageSize,
    initialItems: pagedArticlesData.initialItems,
    initialAmount: pagedArticlesData.initialAmount,
    initialHasMore: pagedArticlesData.initialHasMore,
    secondPageSearchParams: pagedArticlesData.secondPageSearchParams,
    getPageDataFromResponse: pagedArticlesData.getPageDataFromResponse,
    getNextPageSearchParams: pagedArticlesData.getNextPageSearchParams,
  });

  // Filter out duplications
  const deduplicatedArticles = useMemo(() => deduplicateObjectsByKey(articles, 'id'), [articles]);

  const { getAdFormatForPage } = useAdPositions(pagedArticlesData.adPageSkip, pagedArticlesData.adFormats);
  const emptyListAdFormat =
    pagedArticlesData.canShowEmptyListAd && deduplicatedArticles.length === 0 ? getAdFormatForPage(0) : null;

  const titleComponent = (
    <div className={styles.mainContent}>
      <StoryHeader className={styles.header} headingData={{ text: title }}>
        <StoryHeader.Headline className={styles.heading} />
      </StoryHeader>
    </div>
  );

  return (
    <>
      <Ad
        adFormat="leaderboard"
        adSlot="t1"
        className={cx(styles.topMargin, { [styles.hideLeaderboardAdOnMobile]: isLeaderboardAdHiddenOnMobile })}
      />
      <Container fullWidthSm fullWidthMd className={styles.topMargin} {...getThemeDataAttributes(theme)}>
        <InfiniteScrollArticleList
          teaserHasDateTime={pagedArticlesData.teaserHasDateTime}
          loadMore={loadPage}
          prependChildren={titleComponent}
          getAdFormatForPage={getAdFormatForPage}
          articles={deduplicatedArticles}
          isLoading={isLoading}
          hasMore={hasMore}
          pageSize={pagedArticlesData.pageSize}
          initialPageSize={pagedArticlesData.initialAmount}
          teaserPropsOptions={teaserPropsOptions}
        />
        {emptyListAdFormat && <Ad adFormat={emptyListAdFormat} adSlot="b" />}
      </Container>
    </>
  );
}
