import { LinkText } from '@mediahuis/chameleon-react';
import { MySectionPageProps } from '../domain/my-section-page-props';
import styles from './my-section-page-tiles.module.scss';

export function MySectionPageTiles({ sectionsToRender, createSelectSectionHandler }: MySectionPageProps) {
  return (
    <ol className={styles.groupTilesWrapper} data-testid="mysectionpage-tiles">
      {sectionsToRender.map(section => (
        <li key={section.name} data-testid="mysectionpage-tile">
          <LinkText className={styles.tileLink} onClick={createSelectSectionHandler({ section })} href={section.url}>
            {section.name}
          </LinkText>
        </li>
      ))}
    </ol>
  );
}
