import { ReactElement, useMemo } from 'react';

import { Ad } from '@hubcms/feature-ads';
import { useAdPositions } from '@hubcms/utils-ads';
import { usePager } from '@hubcms/utils-pagination';
import { SearchPageTemplateData, SectionData } from '@hubcms/domain-section';
import { Container } from '@hubcms/ui-container';
import { deduplicateObjectsByKey } from '@hubcms/utils-browser';
import { TeaserPropsOptions } from '@hubcms/domain-teaser';

import { InfiniteScrollArticleList } from '../InfiniteScrollArticleList';

import SearchPageTemplate from './SearchPageTemplate';

type SearchPageProps = {
  sectionData: SectionData;
  templateData: SearchPageTemplateData;
  teaserPropsOptions: TeaserPropsOptions;
};

const SearchPage = ({ sectionData, templateData, teaserPropsOptions }: SearchPageProps): ReactElement => {
  const { pagedArticlesData } = sectionData;
  const {
    items: articles,
    isLoading,
    loadPage,
    hasMore,
  } = usePager({
    pageSize: pagedArticlesData.pageSize,
    initialItems: pagedArticlesData.initialItems,
    initialAmount: pagedArticlesData.initialAmount,
    initialHasMore: pagedArticlesData.initialHasMore,
    secondPageSearchParams: pagedArticlesData.secondPageSearchParams,
    getPageDataFromResponse: pagedArticlesData.getPageDataFromResponse,
    getNextPageSearchParams: pagedArticlesData.getNextPageSearchParams,
  });

  // Filter out duplications
  const deduplicatedArticles = useMemo(() => deduplicateObjectsByKey(articles, 'id'), [articles]);

  const { getAdFormatForPage } = useAdPositions(pagedArticlesData.adPageSkip, pagedArticlesData.adFormats);
  const emptyListAdFormat =
    pagedArticlesData.canShowEmptyListAd && deduplicatedArticles.length === 0 ? getAdFormatForPage(0) : null;

  const leaderboardAdFormat = sectionData.leadingAdFormat;

  return (
    <>
      <SearchPageTemplate leaderboardAdFormat={leaderboardAdFormat} templateData={templateData} />
      <Container fullWidthSm fullWidthMd>
        <InfiniteScrollArticleList
          teaserHasDateTime={pagedArticlesData.teaserHasDateTime}
          articles={deduplicatedArticles}
          pageSize={pagedArticlesData.pageSize}
          initialPageSize={pagedArticlesData.initialAmount}
          isLoading={isLoading}
          hasMore={hasMore}
          getAdFormatForPage={getAdFormatForPage}
          loadMore={loadPage}
          teaserPropsOptions={teaserPropsOptions}
        />
        {emptyListAdFormat && <Ad adFormat={emptyListAdFormat} adSlot="b" />}
      </Container>
    </>
  );
};
export default SearchPage;
