import { MySectionTemplateData } from '@hubcms/domain-section';

export function validateMySectionLayout(groupOptionsLayout: string): MySectionTemplateData['layout'] {
  switch (groupOptionsLayout) {
    case 'tiles':
    case 'list':
      return groupOptionsLayout;
    default:
      return 'list';
  }
}
