import { Paragraph, TextField } from '@mediahuis/chameleon-react';
import { ChangeEventHandler, FormEventHandler, KeyboardEvent, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { sendTrackingEvent } from '@hubcms/data-access-tracking';
import { createViewSearchResultsEvent } from '@hubcms/utils-tracking';
import { SearchPageTemplateData } from '@hubcms/domain-section';
import { LeaderboardAd } from '@hubcms/feature-ads';

import styles from './search-page.module.scss';
import { useSearchResultMsg } from './useSearchResultMsg';

type SearchPageTemplateProps = {
  leaderboardAdFormat: string;
  templateData: SearchPageTemplateData;
};

function SearchPageTemplate({ leaderboardAdFormat, templateData }: SearchPageTemplateProps) {
  const searchFieldRef = useRef<HTMLInputElement>(null);
  const [searchBoxValue, setSearchboxValue] = useState(templateData.query);

  useEffect(() => {
    if (templateData.query) {
      setSearchboxValue(templateData.query);
      sendTrackingEvent(
        createViewSearchResultsEvent({
          searchterm: templateData.query,
          searchresults: templateData.resultCount > 0,
        }),
      );
    }
  }, [templateData.query, templateData.resultCount]);

  const queryStringParams = new URLSearchParams({ q: searchBoxValue });
  if (templateData.sortBy && templateData.sortBy !== 'relevance') queryStringParams.set('sort', templateData.sortBy);

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  const handleSubmit: FormEventHandler = e => {
    e.preventDefault();
    if (searchBoxValue) {
      const url = new URL(templateData.sectionUrl);
      url.search = queryStringParams.toString();
      window.location.href = url.toString();
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    setSearchboxValue(e.target.value);
  };

  const { resultsMsgNode } = useSearchResultMsg({
    queryString: templateData.query,
    resultCount: templateData.resultCount,
  });

  return (
    <div
      className={cx(styles.searchPage, {
        [styles.withoutQuery]: !templateData.query,
      })}
    >
      {leaderboardAdFormat ? <LeaderboardAd adFormat={leaderboardAdFormat} className={styles.adSlot} /> : null}
      <form onSubmit={handleSubmit} className={styles.form}>
        <TextField
          className={styles.input}
          label={templateData.searchBoxLabel}
          labelHidden
          name="q"
          placeholder={templateData.searchBoxPlaceholder ?? null}
          value={searchBoxValue}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          ref={searchFieldRef}
          clearButton
        />
      </form>

      {templateData.query && (
        <Paragraph as="div" className={styles.resultMsg}>
          {resultsMsgNode}
        </Paragraph>
      )}
    </div>
  );
}

export default SearchPageTemplate;
