import dynamic from 'next/dynamic';

import { ListPageResultPlaceholderProps, ListPageResultTeaserProps } from './types';

const ListPageResultTeaser = dynamic<ListPageResultTeaserProps>(() => import('./ListPageResultTeaser').then(mod => mod.default));
const ListPageResultPlaceholder = dynamic<ListPageResultPlaceholderProps>(() =>
  import('./ListPageResultPlaceholder').then(mod => mod.default),
);

export { ListPageResultTeaser, ListPageResultPlaceholder };
export { getListPageResultTeaserProps } from './getListPageResultTeaserProps';
