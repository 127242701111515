import { TagPageTemplateData } from '@hubcms/domain-section';
import { ArticleTeaserData } from '@hubcms/domain-teaser';
import { Elements } from '@hubcms/feature-story-elements';
import { StoryHeader, StorySection } from '@hubcms/ui-story';
import { TagPageBlock } from '@hubcms/ui-teaser-layout';
import { tagPageHeadingProps } from '@hubcms/brand';

import styles from './tagpage.module.scss';

type TagPageTemplateProps = {
  templateData: TagPageTemplateData;
  tagBlockArticles: ArticleTeaserData[];
};

function TagPageTemplate({ templateData, tagBlockArticles }: TagPageTemplateProps) {
  return (
    <>
      <div className={styles.introGrid}>
        <StoryHeader className={styles.header} {...templateData.storyHeaderData}>
          <StoryHeader.Headline className={styles.heading} {...tagPageHeadingProps} />
          <StoryHeader.Intro noPaddingInline />
        </StoryHeader>
        <StorySection className={styles.storylineContent}>
          <Elements elements={templateData.bodyElements} />
        </StorySection>
      </div>
      {tagBlockArticles.length ? <TagPageBlock articles={tagBlockArticles} theme="none" /> : null}
    </>
  );
}

export default TagPageTemplate;
