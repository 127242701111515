import { PictureRelationFragment, RichTextFieldFragment } from '@hubcms/domain-cook';

const ATTRIBUTES: Record<string, string[]> = {
  a: ['href', 'target'],
};

const RENAMES: Record<string, string> = {
  i: 'em',
  b: 'strong',
};

export function flattenRichTextFieldFragments(richTextFieldFragmentList: RichTextFieldFragment[]): string {
  return richTextFieldFragmentList
    .map(node => {
      if (isTextNode(node)) {
        return node.text;
      }
      if (node.type === 'br') {
        return '<br>';
      }
      if (isImgNode(node)) {
        const alignment = node.align && node.align !== 'undefined' ? `float: ${node.align}` : '';
        return `<img src="${node.relation.content.fields.sixteenNine.href_full}" alt="${node.relation.caption}" style="max-width: 200px; ${alignment}" />`;
      }
      const attributes = mapAttributes(node);
      const nodeName = RENAMES[node.type] || node.type;
      const innerHtml = isNodeWithChildren(node) ? flattenRichTextFieldFragments(node.children) : '';
      return `<${nodeName}${attributes.length > 0 ? ` ${attributes.join(' ')}` : ''}>${innerHtml}</${nodeName}>`;
    })
    .join('');
}

function isTextNode(node: RichTextFieldFragment): node is RichTextFieldFragment & { text: string } {
  return node.type === 'text';
}

type ImgNode = {
  align: string;
  relation: {
    content: PictureRelationFragment;
    caption: string;
  };
};
function isImgNode(node: RichTextFieldFragment): node is RichTextFieldFragment & ImgNode {
  return node.type === 'img';
}
function isNodeWithChildren(node: RichTextFieldFragment): node is RichTextFieldFragment & { children: RichTextFieldFragment[] } {
  return 'children' in node;
}

function mapAttributes(node: RichTextFieldFragment) {
  const attributeKeys = ATTRIBUTES[node.type] || [];
  return attributeKeys.filter(isKeyOf(node)).map(key => `${key}="${node[key]}"`);
}

function isKeyOf(node: RichTextFieldFragment) {
  return (key: string): key is keyof RichTextFieldFragment => {
    return key in node;
  };
}
