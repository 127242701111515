import { ReactNode } from 'react';

import { TextReplacements } from './types';

type EnrichedReplacements = Array<{
  key: string;
  isHtmlTag: boolean;
  regExp: RegExp | null;
  replacementFn: TextReplacements[number];
}>;
type XmlChildMapper = (item: HTMLElement) => ReactNode;

function isHtmlTag(key: string): boolean {
  return /<.*?>/.test(key);
}

/**
 * Combines the replacement keys in a full fletched regexp
 * @param replacements
 * @returns combined RegExp where for html tags the whole tag is selected (e.g. /({{article_headline}})|({{article_url}})/gim)
 */
function createCombinedRegExp(keys: string[]): RegExp {
  return new RegExp(`(${keys.join(')|(')})`, 'gim');
}

/**
 * Creates a single RegExp from a key
 * @param key
 * @returns RegExp where the start and end are fixed to the key
 */
function createSingleRegExp(key: string): RegExp {
  return new RegExp(`^${key}$`, 'gim');
}

function textReplacementMapper(enrichedReplacements: EnrichedReplacements) {
  return function (text: string): ReactNode {
    const matchingRegExp = enrichedReplacements.find(({ regExp }) => regExp?.test(text));

    if (matchingRegExp) {
      const { replacementFn, regExp } = matchingRegExp;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const children = text.replace(regExp!, '$1');
      return execReplacementFn(replacementFn, children, {});
    }

    return text;
  };
}

function execReplacementFn(replacementFn: TextReplacements[number], children: ReactNode, props: Record<string, unknown>) {
  if (typeof replacementFn === 'function') {
    return replacementFn({ children, ...props });
  }
  return replacementFn;
}

function mapAttributesToJSX(attributeName: string, attributeValue: string | null) {
  switch (attributeName) {
    case 'class':
      return ['className', attributeValue];
    case 'style': {
      const props = (attributeValue ?? '').split(/\s*;\s*/);
      const cssProps = props.map(prop => {
        const [name, value] = prop.split(/\s*:\s*/);
        return [name.replace(/-./g, x => x[1].toUpperCase()), value];
      });
      return ['style', Object.fromEntries(cssProps)];
    }
    default:
      return [attributeName, attributeValue];
  }
}

/**
 * This function can replace text in a pure (non-html) string and returns a string.
 *
 * There can only be fixed string (e.g. {{brand-plus-main}})
 * For replacing HTML tags or recursive replacements, use replaceText
 *
 */
export function replacePureString(text: string, replacements: TextReplacements): string {
  let output = text;
  Object.entries(replacements).forEach(([key, replacement]) => {
    const regExp = new RegExp(key, 'gim');
    output = output.replace(regExp, replacement as string);
  });
  return output;
}

/**
 * This function can replace text and HTML tags in a string and returns an array of ReactNode.
 *
 * There are two kind of replacements, HTML tag (e.g.: <u> or <b>) or fixed string (e.g. {{brand-plus-main}})
 * Note: This function can only be used client side, due to the usage of DOMParser.
 */
export function replaceText(text: string, replacements: TextReplacements): ReactNode[] {
  const enrichedReplacements: EnrichedReplacements = Object.entries(replacements).map(([key, replacementFn]) => ({
    key: key.toLowerCase(),
    isHtmlTag: isHtmlTag(key),
    regExp: isHtmlTag(key) ? null : createSingleRegExp(key),
    replacementFn,
  }));
  const htmlTags = enrichedReplacements.filter(({ isHtmlTag }) => isHtmlTag);
  const nonHtmlTags = enrichedReplacements.filter(({ isHtmlTag }) => !isHtmlTag);
  const combinedRegExp = createCombinedRegExp(nonHtmlTags.map(({ key }) => key));

  // To consistently find HTML-like tags, we can parse the string as XML
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(
    `<HUBCMS>${text.replace(/&lt;(.+?)&gt;/gim, '<$1>').replace(/<(br|img)>/gim, '<$1/>')}</HUBCMS>`,
    'text/xml',
  );
  const items = Array.from<HTMLElement>((xmlDoc.firstChild?.childNodes as never) ?? []);

  const xmlChildMapper: XmlChildMapper = item => {
    if (item.nodeName === '#text') {
      /**
       * The combined RegExp splits the string in blocks non-eagerly that are either matches or remaining text or undefined
       * (e.g.: "This example text with a logo {{brand-plus-main}} and a {{article_url}}."
       *  would split in ["This example text with a logo ", "{{brand-plus-main}}", " and a ", "{{article_url}}", "."])
       *
       * Each of these parts is then again tested with each single RegExp and if there is a match, the match is replaced.
       */
      return item.textContent
        ?.split(combinedRegExp)
        .filter(value => value !== undefined)
        .map(textReplacementMapper(enrichedReplacements));
    }

    const htmlTag = htmlTags.find(({ key }) => key === `<${item.nodeName}>`.toLowerCase());
    if (htmlTag) {
      const { replacementFn } = htmlTag;
      const children = Array.from<HTMLElement>(item.childNodes as never).map(xmlChildMapper); // Recursively map the children of this node
      const attributes = Object.fromEntries(
        item.getAttributeNames().map(name => mapAttributesToJSX(name, item.getAttribute(name))),
      );
      return execReplacementFn(replacementFn, children, attributes);
    }

    // This is an unknown XML node, which we can just render back as text
    return [
      `<${item.nodeName}>`,
      Array.from<HTMLElement>(item.childNodes as never).map(xmlChildMapper), // Recursively map the children of this node
      `</${item.nodeName}>`,
    ];
  };

  return items.filter(item => item.nodeName !== 'parsererror').map(xmlChildMapper);
}

/**
 * This function can replace text in a string and returns an array of ReactNode.
 *
 * There can only be fixed string (e.g. {{brand-plus-main}})
 * For replacing HTML tags or recursive replacements, use replaceText
 *
 */
export function replaceTextSimple(text: string, replacements: Record<string, ReactNode>): ReactNode[] {
  const combinedRegExp = createCombinedRegExp(Object.keys(replacements));
  return text.split(combinedRegExp).map(part => {
    const replacingNode = replacements[part];
    return replacingNode || part;
  });
}
