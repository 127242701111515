import { useEffect, useRef } from 'react';
import { useIntersectionObserver } from '@hubcms/utils-browser';

interface InfiniteScrollObserverProps {
  loadMore: () => void;
  className?: string;
}

export function InfiniteScrollObserver({ loadMore, className }: InfiniteScrollObserverProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const ioEntry = useIntersectionObserver(ref, { threshold: 1 });
  const isIntersecting = !!ioEntry?.isIntersecting;

  useEffect(() => {
    if (isIntersecting) {
      loadMore();
    }
  }, [isIntersecting, loadMore]);

  return <div ref={ref} className={className} />;
}
