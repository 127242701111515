import { MouseEvent, useState } from 'react';

import { SectionParams } from '@hubcms/domain-cook';
import { GTitle } from '@hubcms/domain-teaser-grid';
import { getMySectionOverviewConfiguration, getSectionPreference, updateMySectionCookies } from '@hubcms/utils-my-section';
import { createUrl } from '@hubcms/utils-url';

export function useSublinkOptions(sectionParams: SectionParams, sectionName: string): GTitle['data']['subLink'] {
  const [hasUpdatedCookie, setHasUpdatedCookie] = useState(false);

  if (!sectionParams['mysection.overviewpage']) {
    // we're not on a subsection of a mysection page
    return null;
  }

  const handleSublinkClick = (e: MouseEvent) => {
    e.preventDefault();

    // collect
    const mySectionOverviewConfiguration = getMySectionOverviewConfiguration(sectionParams);
    const identifierValue = sectionParams[mySectionOverviewConfiguration.identifierParameter];

    // update cookies
    updateMySectionCookies({
      sectionUrl: mySectionOverviewConfiguration.overviewPageUrl,
      chosenPreferenceUrl: window.location.href,
      storeMySectionSelectionInCookie: mySectionOverviewConfiguration.storeMySectionSelectionInCookie,
      type: mySectionOverviewConfiguration.mySectionType,
      identifierLabel: mySectionOverviewConfiguration.identifierLabel,
      identifierValue,
      selectionName: sectionName,
    });

    // update state
    setHasUpdatedCookie(true);
  };

  const preference = createUrl(getSectionPreference(sectionParams['mysection.overviewpage']));

  if (typeof window !== 'undefined' && preference.pathname !== window.location.pathname && !hasUpdatedCookie) {
    return {
      text: sectionParams['mysection.setasmysection'],
      onClick: handleSublinkClick,
    };
  }

  return {
    text: sectionParams['mysection.changemysection'],
    url: sectionParams['mysection.overviewpage'],
  };
}
