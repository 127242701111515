import { isLeaderboardAdHiddenOnMobile } from '@hubcms/brand';
import cx from 'classnames';
import { Ad } from '../Ad/Ad';
import styles from './leaderboard-ad.module.scss';

type LeaderboardAdProps = {
  adFormat?: string;
  className: string;
};

export const LeaderboardAd = ({ adFormat = 'leaderboard', className }: LeaderboardAdProps) => (
  <Ad
    adFormat={adFormat}
    adSlot="t1"
    className={cx(className, { [styles.hideLeaderboardAdOnMobile]: isLeaderboardAdHiddenOnMobile })}
  />
);
