import { Subsection } from '@hubcms/domain-cook';

function flattenSection(section: Subsection): Subsection[] {
  return [section, ...section.subSections.flatMap(flattenSection)];
}

function filterSection(section: Subsection, excludedSections: string[]): boolean {
  return !excludedSections.includes(section.uniqueName);
}

function mapSection(section: Subsection, identifierParameterKey: string) {
  return {
    name: section.name,
    url: section.href,
    identifierValue: section.parameters.find(p => p.key === identifierParameterKey)?.value ?? '',
  };
}

export function flattenFilterAndMapSections(sections: Subsection[], excludedSections: string[], identifierParameterKey: string) {
  return sections
    .flatMap(flattenSection)
    .filter(section => filterSection(section, excludedSections))
    .map(section => mapSection(section, identifierParameterKey));
}
