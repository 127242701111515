import cx from 'classnames';
import { ImageElement } from '@hubcms/ui-image';
import { Heading, RichContent } from '@mediahuis/chameleon-react';
import { AuthorProfileTemplateData } from '@hubcms/domain-section';

import { AuthorSocial } from './AuthorSocial';

import styles from './author-page.module.scss';

interface AuthorHeaderProps {
  withTopMargin: boolean;
  personalData: AuthorProfileTemplateData;
  headshotImage?: string;
}

export function AuthorHeader({ withTopMargin, personalData, headshotImage }: AuthorHeaderProps) {
  const { name, bio, email, twitterHandle, facebookProfile, instagramProfile, phoneNumber, authorFunction } = personalData;

  return (
    <header data-testid="author-hero" className={cx(styles.header, { [styles.withTopMargin]: withTopMargin })}>
      {headshotImage && (
        <ImageElement
          className={styles.authorHeadshot}
          src={headshotImage}
          alt=""
          sizes={{ xs: '160px' }}
          aspectRatio="oneOne"
          layout="cover"
        />
      )}
      <div className={styles.authorDetails}>
        <Heading level={3} className={styles.name}>
          {name}
        </Heading>
        <Heading level={4} className={styles.function}>
          {authorFunction}
        </Heading>
        {bio && (
          <div className={styles.description}>
            <RichContent dangerouslySetInnerHTML={{ __html: bio }} />
          </div>
        )}
        <div className={styles.socialMediaItemList}>
          <AuthorSocial href={`mailto:${email}`} brand="email" caption={email} />
          <AuthorSocial href={twitterHandle} brand="twitter" caption={twitterHandle?.replace(/\/$/, '')?.split('/').pop()} />
          <AuthorSocial href={facebookProfile} brand="facebook" caption={facebookProfile?.replace(/\/$/, '')?.split('/').pop()} />
          <AuthorSocial
            href={instagramProfile}
            brand="instagram"
            caption={instagramProfile?.replace(/\/$/, '')?.split('/').pop()}
          />
          <AuthorSocial href={`tel:${phoneNumber?.replace(/\D/g, '')}`} brand="phone" caption={phoneNumber} />
        </div>
      </div>
    </header>
  );
}
