import { SectionParams } from '@hubcms/domain-cook';

import { PagedArticlesData, createPagedArticlesData } from '@hubcms/domain-section';
import { ArticleTeaserData } from '@hubcms/domain-teaser';

type BuildOptions = {
  initialItems: ArticleTeaserData[];
  initialPageSizeKey: string;
  extraPageSizeKey: string;
  total?: number;
  adFormatsKey: string;
  adPageSkipSequenceKey: string;
};

export function buildPagination<PaginationResponse>(
  sectionParams: SectionParams,
  options: BuildOptions,
): PagedArticlesData<PaginationResponse> {
  const { total = 0, initialItems, initialPageSizeKey, extraPageSizeKey, adFormatsKey, adPageSkipSequenceKey } = options;
  const initialAmount = Number(sectionParams[initialPageSizeKey]) || 1;
  const pageSize = Number(sectionParams[extraPageSizeKey]) || 8;

  const adFormats = sectionParams[adFormatsKey]?.split(',').map(format => format.trim()) || [];
  const adPageSkip =
    sectionParams[adPageSkipSequenceKey]
      ?.split(',')
      .map(Number)
      .filter(n => !isNaN(n)) || [];
  if (adPageSkip.length === 0) {
    adPageSkip.push(0);
  }

  return createPagedArticlesData<PaginationResponse>({
    initialItems,
    initialAmount,
    pageSize,
    initialHasMore: total > initialItems.length,
    adFormats,
    adPageSkip,
  });
}
