import { OnMhluHomepageGroup } from '@hubcms/domain-cook';
import { SectionData } from '@hubcms/domain-section';
import { mapToGroupOptionsRecord } from '@hubcms/utils-storyblock';

export function getHomePageAdParameters(nonContent: OnMhluHomepageGroup['nonContent'] = []): SectionData['storyblockAds'] {
  const homePageAdParameters = nonContent
    .filter(content => content.type === 'ad')
    .map(ad => ({ ...ad, groupOptions: mapToGroupOptionsRecord(ad.groupOptions) }))
    .filter(({ groupOptions: { adPosition } }) => adPosition && adPosition !== 'hide')
    .sort((left, right) => {
      const positionLeft = left.groupOptions.adPosition ?? -1;
      const positionRight = right.groupOptions.adPosition ?? -1;

      return Number(positionLeft) - Number(positionRight);
    })
    .map(({ groupOptions }) => ({
      positions: [Number(groupOptions.adPosition)],
      formats: [groupOptions.adFormat],
    }))
    .reduce(
      (prevValue, current) => {
        return {
          positions: [...prevValue.positions, ...current.positions],
          formats: [...prevValue.formats, ...current.formats],
        };
      },
      { positions: [], formats: [] },
    );
  return homePageAdParameters;
}
