import { CookData, IndexPageData, isMhluSectionPage } from '@hubcms/domain-cook';
import { createMhluSectionTemplateData, createSectionData } from '@hubcms/domain-section';
import { getStoryblocks } from '@hubcms/utils-storyblock';
import { getPageDataFromExtendedDataSource } from '@hubcms/utils-pagination';
import { getIsTeaserDateShown } from '@hubcms/utils-teaser';
import { partitionArray } from '@hubcms/utils-browser';
import { TeaserPropsOptions } from '@hubcms/domain-teaser';

import { buildPagination } from '../../utils/buildPagination';
import { getArticles } from '../../utils/getArticles';
import { MapSectionPageReturnType } from '../../domain/map-section-page-return-type';

export function createMhluSectionPage(cookData: CookData, teaserPropsOptions: TeaserPropsOptions): MapSectionPageReturnType {
  if (!isMhluSectionPage(cookData)) {
    return null;
  }
  const { sectionParams } = cookData;

  const pagedArticlesData = buildMhluSectionPagedArticlesData(cookData, teaserPropsOptions);
  const sectionData = createSectionData({ pagedArticlesData });

  const storyblocks = getStoryblocks(cookData.context.rootGroup as never, {
    sectionParams,
    teaserPropsOptions,
    areaNames: ['news'],
  });
  const [htmlStoryblocks, deskedContent] = partitionArray(
    storyblocks,
    storyblock => storyblock.storyblockOptions.type === 'HtmlGroup',
  );
  sectionData.storyblocks = htmlStoryblocks;

  const deskedTeaserData = deskedContent.map(g => Object.values(g.unflattenedTeaserAreas)).flat(2);
  const templateData = createMhluSectionTemplateData({
    deskedTeaserData,
    groupTitle: cookData.resolution.section.name,
    asideRecirculationStoryblocks: getStoryblocks(cookData.sectionAside?.page?.rootGroup as never, {
      sectionParams,
      teaserPropsOptions,
    }),
  });

  if (pagedArticlesData.initialItems.length === 0 && deskedTeaserData.length === 0) {
    sectionData.noContentMessage = sectionParams['section-tag.noresults'];
  }

  sectionData.leadingAdFormat = 'leaderboard';
  sectionData.templateDividerAdFormat = sectionParams['b2b.section.divider.adformat'];

  return {
    sectionData,
    templateData,
  };
}

function buildMhluSectionPagedArticlesData(
  cookData: Omit<CookData<IndexPageData>, 'mostRecentArticles'>,
  teaserPropsOptions: TeaserPropsOptions,
) {
  const { sectionParams } = cookData;
  const pagedArticlesData = buildPagination<IndexPageData>(sectionParams, {
    initialItems: getArticles(cookData.latestArticles?.items, teaserPropsOptions),
    initialPageSizeKey: 'section.pagination.webInitialAmount',
    extraPageSizeKey: 'section.pagination.webExtraAmount',
    total: cookData.latestArticles?.total,
    adFormatsKey: 'b2b.section.default.ads.adformat',
    adPageSkipSequenceKey: 'b2b.section.default.ads.pageskip',
  });
  pagedArticlesData.teaserHasDateTime = getIsTeaserDateShown('section');
  pagedArticlesData.getPageDataFromResponse = responseData => {
    const pageData = getPageDataFromExtendedDataSource(responseData.latestArticles);
    return {
      pageableItems: getArticles(pageData.pageableItems, teaserPropsOptions),
      hasMore: pageData.hasMore,
    };
  };
  pagedArticlesData.asideRecirculationStoryblocks = getStoryblocks(cookData.sectionAsideList?.page?.rootGroup as never, {
    sectionParams,
    teaserPropsOptions,
    hasNoAds: true,
  });

  /**
   * Unlike tag and author pages, section pages will not always request latestArticles from the server
   * For example, when the section contains enough desked articles to cover for the opening block and the initial page size
   * In such cases, latestArticles.total will be 0, and latestArticles.items will be an empty array
   * Without checking for isDefaultLatestArticles, the initialHasMore will be true — although there are possibly more articles to fetch
   * Use isDefaultLatestArticles as a proxy to determine whether we should at least attempt to fetch more articles
   * If !isDefaultLatestArticles, latestArticles.total will represent the correct total amount of articles
   */
  if (cookData.latestArticles.offset === 0 && cookData.latestArticles.count === 0 && cookData.latestArticles.total === 0) {
    pagedArticlesData.initialHasMore = true;
  }

  return pagedArticlesData;
}
