import { TStoryblock } from '@hubcms/domain-storyblock';
import { ArticleTeaserData } from '@hubcms/domain-teaser';

export type PagedArticlesData<PaginationResponse> = {
  pageSize: number;
  initialItems: ArticleTeaserData[];
  initialHasMore: boolean;
  initialAmount: number;
  secondPageSearchParams?: string;
  getNextPageSearchParams?: (response: PaginationResponse) => string;
  getPageDataFromResponse: (response: PaginationResponse) => { pageableItems: ArticleTeaserData[]; hasMore: boolean };
  teaserHasDateTime: boolean;
  adFormats: string[];
  adPageSkip: number[];
  canShowEmptyListAd: boolean;
  asideRecirculationStoryblocks: TStoryblock[];
};

export function createPagedArticlesData<PaginationResponse>({
  initialItems = [],
  initialAmount = initialItems.length,
  pageSize = 1,
  initialHasMore = false,
  getPageDataFromResponse = () => ({ pageableItems: [], hasMore: false }),
  secondPageSearchParams,
  getNextPageSearchParams,
  teaserHasDateTime = true,
  adFormats = [],
  adPageSkip = [0],
  canShowEmptyListAd = false,
  asideRecirculationStoryblocks = [],
  ...optionalProperties
}: Partial<PagedArticlesData<PaginationResponse>> = {}): PagedArticlesData<PaginationResponse> {
  return {
    initialItems,
    initialAmount,
    pageSize,
    initialHasMore,
    getPageDataFromResponse,
    secondPageSearchParams,
    getNextPageSearchParams,
    teaserHasDateTime,
    adFormats,
    adPageSkip,
    canShowEmptyListAd,
    asideRecirculationStoryblocks,
    ...optionalProperties,
  };
}
