import { ReactElement } from 'react';

import { Heading } from '@mediahuis/chameleon-react';
import { LeaderboardAd, PipeAd } from '@hubcms/feature-ads';
import { MySectionPage } from '@hubcms/feature-mysectionpage';
import { CookData } from '@hubcms/domain-cook';
import { Container, PipedContainer } from '@hubcms/ui-container';
import { mapSectionPage } from '@hubcms/utils-section';
import { StoryblockList } from '@hubcms/feature-storyblock';
import { TeaserPropsOptions } from '@hubcms/domain-teaser';

import TagPage from '../../components/TagPage';
import SearchPage from '../../components/SearchPage';
import MHLUSection from '../../components/Section/Pages/MHLUSection';
import AuthorPage from '../../components/AuthorPage';

import styles from './section.module.scss';
import MostRecentPage from '../../components/MostRecent';

type SectionProps = {
  data: CookData;
  teaserPropsOptions: TeaserPropsOptions;
};

const Section = ({ data, teaserPropsOptions }: SectionProps): ReactElement => {
  const sectionPage = mapSectionPage(data, teaserPropsOptions);

  if (!sectionPage) {
    throw Error(`No valid section data for ${data.context?.__typename}`);
  }
  const { sectionData, templateData } = sectionPage;

  if (templateData?.template === 'tag') {
    return <TagPage sectionData={sectionData} templateData={templateData} teaserPropsOptions={teaserPropsOptions} />;
  }

  if (templateData?.template === 'search') {
    return <SearchPage sectionData={sectionData} templateData={templateData} teaserPropsOptions={teaserPropsOptions} />;
  }

  if (templateData?.template === 'author') {
    return <AuthorPage sectionData={sectionData} templateData={templateData} teaserPropsOptions={teaserPropsOptions} />;
  }

  if (templateData?.template === 'most-recent') {
    return <MostRecentPage sectionData={sectionData} templateData={templateData} teaserPropsOptions={teaserPropsOptions} />;
  }

  if (templateData?.template === 'my-section') {
    return <MySectionPage sectionData={sectionData} templateData={templateData} teaserPropsOptions={teaserPropsOptions} />;
  }

  const sectionHeading = (
    <Container>
      <LeaderboardAd className={styles.t1AdSlot} />
    </Container>
  );

  if (templateData?.template === 'mhlu-section') {
    return (
      <>
        {sectionHeading}
        <MHLUSection sectionData={sectionData} templateData={templateData} teaserPropsOptions={teaserPropsOptions} />
      </>
    );
  }

  const leftPipeAd = sectionData.leftPipeAdFormat ? <PipeAd adFormat={sectionData.leftPipeAdFormat} adSlotPrefix="left" /> : null;
  const rightPipeAd = sectionData.rightPipeAdFormat ? (
    <PipeAd adFormat={sectionData.rightPipeAdFormat} adSlotPrefix="right" />
  ) : null;

  // return a default section page
  return (
    <>
      {sectionHeading}
      <PipedContainer leftPipe={leftPipeAd} rightPipe={rightPipeAd}>
        <>
          {sectionData.noContentMessage && (
            <Container>
              <Heading level={4} size="md">
                {sectionData.noContentMessage}
              </Heading>
            </Container>
          )}
          {sectionData.storyblocks.length > 0 && (
            <StoryblockList
              storyblocks={sectionData.storyblocks}
              adPositions={sectionData.storyblockAds.positions}
              adFormats={sectionData.storyblockAds.formats}
              teaserPropsOptions={teaserPropsOptions}
            />
          )}
        </>
      </PipedContainer>
    </>
  );
};
export default Section;
