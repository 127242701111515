import { SubSection } from '../domain/my-section-page-props';

type GroupedSections = { [key: string]: SubSection[] };
export function groupSectionsByLetter(cities: SubSection[]) {
  const groupedSections: GroupedSections = {};

  cities
    .sort((a, b) => a.name.localeCompare(b.name))
    .forEach(section => {
      const startingLetter = section.name.charAt(0).toUpperCase();

      if (!groupedSections[startingLetter]) {
        groupedSections[startingLetter] = [];
      }

      groupedSections[startingLetter].push(section);
    });

  return groupedSections;
}
