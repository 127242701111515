import cx from 'classnames';
import { CSSProperties, Fragment, ReactNode } from 'react';
import { Ad } from '@hubcms/feature-ads';
import { getListPageResultTeaserProps, ListPageResultPlaceholder, ListPageResultTeaser } from '@hubcms/ui-teaser';
import { isAutomatedTeaserIntroShown } from '@hubcms/brand';
import { TeaserPropsOptions, isArticleTeaserData, type TeaserData } from '@hubcms/domain-teaser';
import { TStoryblock } from '@hubcms/domain-storyblock';

import { InfiniteScrollObserver } from '../InfiniteScrollObserver';
import { ListPageDivider } from '../ListPageDivider/ListPageDivider';
import { AsideRecirculation } from '../AsideRecirculation';

import { calculateExtendedSideContentRowSpan } from './calculateExtendedSideContentRowSpan';
import { getAsideRecirculationStoryblocksForPage } from './getAsideRecirculationStoryblocksForPage';
import styles from './infinite-scroll-article-list.module.scss';
import { getInitialSideContentRowSpan } from './getInitialSideContentRowSpan';

interface InfiniteScrollArticleListProps {
  articles: TeaserData[];
  initialPageSize: number;
  pageSize: number;
  isLoading: boolean;
  hasMore: boolean;
  getAdFormatForPage: (index: number) => string | null;
  loadMore: () => void;
  prependChildren?: ReactNode;
  appendChildren?: ReactNode;
  className?: string;
  getAdClassName?: (index: number) => string;
  teaserHasDateTime: boolean;
  asideRecirculationStoryblocks?: TStoryblock[];
  teaserPropsOptions: TeaserPropsOptions;
}

export function InfiniteScrollArticleList({
  articles,
  initialPageSize,
  pageSize,
  isLoading,
  hasMore,
  getAdFormatForPage,
  loadMore,
  prependChildren,
  appendChildren,
  className,
  teaserHasDateTime,
  teaserPropsOptions,
  asideRecirculationStoryblocks = [],
  getAdClassName = () => '',
}: InfiniteScrollArticleListProps) {
  return (
    <div className={cx(styles.content, className)}>
      {prependChildren}
      {articles.filter(isArticleTeaserData).map((article, index, arr) => {
        const isArticleInFirstPage = index <= initialPageSize - 1;
        const currentPageIndex = isArticleInFirstPage ? 0 : 1 + Math.floor((index - initialPageSize) / pageSize);
        const isFirstPage = currentPageIndex === 0;
        const currentPageFirstArticleIndex = isFirstPage ? 0 : pageSize * currentPageIndex + 1;
        const isCurrentLastArticle = index === arr.length - 1;
        const isLastArticle = isCurrentLastArticle && !hasMore;
        const isLastArticleOfFirstPage = index === initialPageSize - 1;
        const isLastArticleOfExtraPage = index === pageSize * currentPageIndex + initialPageSize - 1;
        const isLastArticleOfPage = isFirstPage ? isLastArticleOfFirstPage : isLastArticleOfExtraPage;
        const shouldRenderInbetween = isLastArticleOfPage || isLastArticle;
        const adRow = isFirstPage ? 1 : currentPageFirstArticleIndex;
        const adFormat = getAdFormatForPage(currentPageIndex);
        const isTagIntroB2B = article.contentType === 'tagIntroB2B';
        const teaserProps = getListPageResultTeaserProps(article, isAutomatedTeaserIntroShown, teaserHasDateTime, isTagIntroB2B);
        const asideStoryblocksForPage = shouldRenderInbetween
          ? getAsideRecirculationStoryblocksForPage(asideRecirculationStoryblocks, currentPageIndex)
          : [];
        const initialSideContentRowSpan = getInitialSideContentRowSpan({
          hasPrependChildren: !!prependChildren,
          isLastArticleOfFirstPage,
          initialPageSize,
          pageSize,
        });
        const sideContentRowSpan =
          initialSideContentRowSpan +
          calculateExtendedSideContentRowSpan(
            {
              pageSize,
              nextPageIndex: currentPageIndex + 1,
              maxPage: currentPageIndex + 5,
            },
            getAdFormatForPage,
            asideRecirculationStoryblocks,
          );

        return (
          <Fragment key={article.id}>
            <div className={cx({ [styles.b2bTeaser]: isTagIntroB2B }, styles.mainContent)}>
              <ListPageResultTeaser key={article.id} {...teaserProps} />
              {index !== arr.length - 1 && !isTagIntroB2B && <ListPageDivider />}
            </div>
            {/* ask about ads too close to each other */}
            {shouldRenderInbetween && (asideStoryblocksForPage.length > 0 || adFormat) && (
              <div
                style={
                  {
                    '--i-ad-row-lg': adRow,
                    '--i-sidecontent-grid-row-span': sideContentRowSpan,
                  } as CSSProperties
                }
                className={cx(styles.sideContent)}
              >
                <AsideRecirculation storyblocks={asideStoryblocksForPage} teaserPropsOptions={teaserPropsOptions} />
                {adFormat && (
                  <Ad
                    isSticky
                    adFormat={adFormat}
                    adStickyTop="var(--scale-7)"
                    adSlot="b"
                    adSlotPrefix="recirculation"
                    className={cx(getAdClassName(index))}
                  />
                )}
              </div>
            )}
          </Fragment>
        );
      })}
      {isLoading && (
        <div className={cx(styles.mainContent, styles.placeholder)}>
          <ListPageDivider />
          <ListPageResultPlaceholder hasIntro={isAutomatedTeaserIntroShown} hasInset={{ xs: true, lg: false }} />
        </div>
      )}
      {hasMore && <InfiniteScrollObserver className={cx(styles.mainContent)} loadMore={loadMore} />}
      {appendChildren}
    </div>
  );
}
